import React from "react";
import Layout from "../components/Layout";
import ExternalLink from "../components/ExternalLink";
import "./about.css";

const PROFILES = {
  goodreads: "https://www.goodreads.com/fredcollman",
  letterboxd: "https://letterboxd.com/fredc",
  lastfm: "https://last.fm/user/fredcollman",
  github: "https://github.com/fredcollman",
};

const AboutPage = () => {
  return (
    <Layout>
      <div className="stack-m">
        <h1>Who am I?</h1>
        <section>
          <h2>I make computers do things</h2>
          <p>
            <span class="italic">{'"Full-stack developer"'}</span> means
            different things to different people.
            <ul>
              <li>
                <span className="bold text-m">I build</span> web apps, APIs,
                command-line apps...
              </li>
              <li>
                <span className="bold text-m">I design</span> data models,
                deployment pipelines, reporting dashboards...
              </li>
              <li>
                <span className="bold text-m">I collaborate</span> with project
                managers, product managers, developers, designers, support
                engineers, test engineers, clients, users, the CEO, the work
                experience guy...
              </li>
            </ul>
          </p>
        </section>
        <section className="about-grid">
          <div>
            Currently I live and work in Oxford, UK. Having previously lived in
            Cambridge (too quiet) and Shoreditch (too busy), I&apos;ve found
            Oxford to be just right.
          </div>
          <div>
            My spiritual home, however, is Glastonbury, site of some very fond
            memories.
          </div>
          <div>
            At the weekend you&apos;ll often find me running, cycling or
            bouldering.
          </div>
          <div>
            On rainy days I tend to curl up on my beanbag with a cup of coffee
            and a good book.
          </div>
          <div>
            I&apos;m also making my way through through the IMDb Top 250,
            recently crossing the half-way point. I only know a handful of
            people who can match my encyclopedic knowledge of the Simpsons.
          </div>
        </section>
        <section>
          <h2>What I&apos;ve been up to</h2>
          You can check out what I have been enjoying lately.
          <ul>
            <li>
              Books on{" "}
              <ExternalLink href={PROFILES.goodreads}>Goodreads</ExternalLink>
            </li>
            <li>
              Movies on{" "}
              <ExternalLink href={PROFILES.letterboxd}>Letterboxd</ExternalLink>
            </li>
            <li>
              Music on{" "}
              <ExternalLink href={PROFILES.lastfm}>last.fm</ExternalLink>
            </li>
          </ul>
          My code is on{" "}
          <ExternalLink href={PROFILES.github}>GitHub</ExternalLink>.
        </section>
      </div>
    </Layout>
  );
};

export default AboutPage;
